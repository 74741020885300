import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

import "./Products.scss";
import { Link } from "react-router-dom";

const Products = () => {
    return (
        <div className="prod__parent">
            <div className="prod__container">
                <div className="prod__heading">
                    <h1>Produkty & Realizacje</h1>
                    <p>
                        Wieloletnie doświadczenie i praca przy różnorodnych
                        projektach to powód do domu. Firma WojMixx oferuje
                        szerokie poltfolio produktów do sprzedaży, a także wgląd
                        w dotychczasowe realizacje na terenie całej Polski.
                    </p>
                </div>

                <div className="prod__card">
                    <div className="prod__img">
                        <img
                            src="https://www.jaybro.com.au/pub/media/mageplaza/blog/post/s/a/safezone_1.jpg"
                            alt=""
                        />
                    </div>
                    <div className="prod__content">
                        <p className="sub-text">
                            Ponad 50 Produktów W Ofercie{" "}
                        </p>
                        <h1>Produkty</h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Itaque, maiores, ipsum a aperiam soluta dolore
                            illum impedit repudiandae sequi nemo recusandae iste
                            quae cumque neque perspiciatis ex ducimus. Iste,
                            nam.
                        </p>
                        <Link to="kategorie" className="prod__card--btn">
                            wszytskie produkty <MdArrowForwardIos />
                        </Link>
                    </div>
                </div>

                <div className="prod__card">
                    <div className="prod__img">
                        <img
                            src="https://m.media-amazon.com/images/I/81isSoHsZFL._AC_UF1000,1000_QL80_.jpg"
                            alt=""
                        />
                    </div>
                    <div className="prod__content">
                        <p className="sub-text">Duma Firmy Wojmixx</p>
                        <h1>realizacje</h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Itaque, maiores, ipsum a aperiam soluta dolore
                            illum impedit repudiandae sequi nemo recusandae iste
                            quae cumque neque perspiciatis ex ducimus. Iste,
                            nam.
                        </p>
                        <Link to="realizacje" className="prod__card--btn">
                            Wszytskie Realizacje <MdArrowForwardIos />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
