import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

function MarkdownContent({ content }) {
  return (
    <div className="markdown-content">
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]} // Umożliwia renderowanie HTML (np. <u>)
        components={{
          img: ({ src, alt }) => (
            <img
              src={src.startsWith('http') ? src : `${process.env.REACT_APP_IMG_URL}${src}`}
              alt={alt}
              style={{ maxWidth: '100%' }}
            />
          ),
          // Możesz dostosować inne elementy
          u: ({ node, ...props }) => <u {...props} style={{ textDecoration: 'underline' }} />,
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}

export default MarkdownContent;
