class ApiService {
    constructor(baseURL, token) {
      this.baseURL = baseURL;
      this.token = token;
    }
  
    async fetchData(endpoint) {
      try {
        const response = await fetch(`${this.baseURL}${endpoint}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        return await response.json();
      } catch (error) {
        return null;
      }
    }
  }
  
  // Inicjalizacja ApiService
  const apiService = new ApiService(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_KEY);
  export default apiService;
  