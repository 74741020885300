import React from "react";
import  SEO  from "../components/seo/SEO";

import "./Home.scss";
import {
  Contact,
  Hero,
  Info,
  Products,
  Testimonials,
} from "../components/index";

const Home = () => {
  return (
    <>
      <SEO
        title="Wojmixx Zabezpieczenia przeciwpowodziowe"
        description="Projektowanie, produkcja oraz montaż zabezpieczeń przeciwpowodziowch "
        name="Wojmixx Zabezpieczenia przeciwpowodziowe."
        type="article"
      />

      <div className="home__container">
        <Hero />
        <Info />
        <Products />
        <Testimonials />
        <Contact />

      </div>
    </>
  );
};

export default Home;
