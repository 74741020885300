import React, { useEffect, useRef, useState } from "react";
import "./Slider.scss";
import apiService from "../../ApiService";

const Slider = () => {
    const [sliderData, setSliderData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiService.fetchData(`/slider?populate[SliderImage][populate]=CoverImage`);
                console.log("Fetched Data:", data);
                console.log(data.data.SliderImage) // Sprawdź, czy dane mają poprawny kształt

                setSliderData(data?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const [activeSlide, setactiveSlide] = useState(2);
    const [isAutoPlay, setIsAutoPlay] = useState(true);
    const sliderContainerRef = useRef(null);

    const next = () =>
        setactiveSlide((prev) => (prev === 4 ? 0 : prev + 1));
    const prev = () =>
        setactiveSlide((prev) => (prev === 0 ? 4 : prev - 1));

    const handleUserClick = (action) => {
        setIsAutoPlay(false);
        action();
    };

    const [translateX_1, setTranslateX_1] = useState("150");
    const [translateX_2, setTranslateX_2] = useState("300");
    const [rotateY_1, setRotateY_1] = useState("25");
    const [rotateY_2, setRotateY_2] = useState("45");

    useEffect(() => {
        if (isAutoPlay) {
            const interval = setInterval(next, 5000); // Zmieniaj obrazki co 5 sekund
            return () => clearInterval(interval); // Wyczyść interwał po odmontowaniu komponentu
        }
    }, [isAutoPlay]);

    useEffect(() => {
        const slider = sliderContainerRef.current;
        let touchStartX = 0;
        let touchEndX = 0;

        const handleTouchStart = (e) => {
            touchStartX = e.changedTouches[0].screenX;
        };

        const handleTouchMove = (e) => {
            touchEndX = e.changedTouches[0].screenX;
        };

        const handleTouchEnd = () => {
            if (touchStartX - touchEndX > 50) {
                handleUserClick(next);
            } else if (touchStartX - touchEndX < -50) {
                handleUserClick(prev);
            }
        };

        if (slider) {
            slider.addEventListener("touchstart", handleTouchStart);
            slider.addEventListener("touchmove", handleTouchMove);
            slider.addEventListener("touchend", handleTouchEnd);
        }

        return () => {
            if (slider) {
                slider.removeEventListener("touchstart", handleTouchStart);
                slider.removeEventListener("touchmove", handleTouchMove);
                slider.removeEventListener("touchend", handleTouchEnd);
            }
        };
    }, []);

    const getStyles = (index) => {
        if (activeSlide === index)
            return {
                opacity: 1,
                transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
                zIndex: 10,
            };
        else if (activeSlide - 1 === index)
            return {
                opacity: 1,
                transform: `translateX(-${translateX_1}px) translateZ(-400px) rotateY(${rotateY_1}deg)`,
                zIndex: 9,
            };
        else if (activeSlide + 1 === index)
            return {
                opacity: 1,
                transform: `translateX(${translateX_1}px) translateZ(-400px) rotateY(-${rotateY_1}deg)`,
                zIndex: 9,
            };
        else if (activeSlide - 2 === index)
            return {
                opacity: 1,
                transform: `translateX(-${translateX_2}px) translateZ(-600px) rotateY(${rotateY_2}deg)`,
                zIndex: 8,
            };
        else if (activeSlide + 2 === index)
            return {
                opacity: 1,
                transform: `translateX(${translateX_2}px) translateZ(-600px) rotateY(-${rotateY_2}deg)`,
                zIndex: 8,
            };
        else if (index < activeSlide - 2)
            return {
                opacity: 0,
                transform: `translateX(-480px) translateZ(-500px) rotateY(35deg)`,
                zIndex: 7,
            };
        else if (index > activeSlide + 2)
            return {
                opacity: 0,
                transform: `translateX(480px) translateZ(-500px) rotateY(-35deg)`,
                zIndex: 7,
            };
    };

    return (
        <div className="parent">
            <div className="slider__container" ref={sliderContainerRef}>
                {sliderData.SliderImage?.length > 0 && sliderData.SliderImage.map((item, i) => (
                    <React.Fragment key={item.id}>
                        <div
                            className="slide"
                            style={{
                                width: "100%",
                                height: "100%",

                                boxShadow: `0 5px 20px #000b376a`,
                                ...getStyles(i),
                            }}
                        >
                            <img src={process.env.REACT_APP_IMG_URL + item.CoverImage.formats.large.url} alt={item.Alt} />

                            <h1>{item.Title}</h1>
                            {/* <SliderContent {...item} /> */}
                        </div>
                        <div
                            className="reflection"
                            style={{
                                background: `linear-gradient(to bottom, #000b376a, transparent)`,
                                ...getStyles(i),
                            }}
                        />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Slider;

const SliderContent = (props) => {
    return (
        <div className="sliderContent">
            <img src={props} alt="" />
        </div>
    );
};




// {data.map((item, i) => (
//     <React.Fragment key={item.id}>
//         <div
//             className="slide"
//             style={{
//                 width: "100%",
//                 height: "100%",

//                 boxShadow: `0 5px 20px #000b376a`,
//                 ...getStyles(i),
//             }}
//         >
//             <img src={item.img} alt={item.alt} />

//             <h1>{item.title}</h1>
//             {/* <SliderContent {...item} /> */}
//         </div>
//         <div
//             className="reflection"
//             style={{
//                 background: `linear-gradient(to bottom, #000b376a, transparent)`,
//                 ...getStyles(i),
//             }}
//         />
//     </React.Fragment>
// ))}