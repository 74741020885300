import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import apiService from "../ApiService";
import SEO from "../components/seo/SEO";
import "./Products.scss";

export default function Products() {
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [currentCategory, setCurrentCategory] = useState();
    const [items, setItems] = useState([]);
    const [type, setType] = useState("");

    function getLastSegment(path) {
        const segments = path.split("/");
        return segments.filter(Boolean).pop();
    }

    useEffect(() => {
        const fetchCategoriesAndProducts = async () => {
            try {
                const categorySlug = getLastSegment(location);
                const categoryFilter =
                    categorySlug && categorySlug !== "kategorie"
                        ? `?filters[parent][Slug][$eq]=${categorySlug}&populate=CoverImage`
                        : "?filters[parent][$null]=true&populate=CoverImage";

                const categoriesData = await apiService.fetchData(
                    `/categories${categoryFilter}`
                );
                const productsData = await apiService.fetchData(
                    `/products?filters[category][Slug][$eq]=${categorySlug}&populate=CoverImage`
                );

                const currentCategory = await apiService.fetchData(
                    `/categories?filters[Slug][$eq]=${categorySlug}`
                );

                setType("");
                setCategories(categoriesData?.data || []);
                setProducts(productsData?.data || []);
                setCurrentCategory(currentCategory?.data);

                if (categoriesData?.data.length > 0) {
                    setType("categories");
                    setItems(categoriesData?.data || []);
                } else {
                    setType("products");
                    setItems(productsData?.data || []);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchCategoriesAndProducts();
    }, [location]);

    return (
        <>
            <SEO
                title="Wojmixx sp. z o.o. Produkty"
                description="Projektowanie, produkcja oraz montaż zabezpieczeń przeciwpowodziowch "
                name="this is name metadata."
                type="article"
            />
            <div className="products__parent">
                {currentCategory &&
                    currentCategory.length > 0 &&
                    currentCategory[0].Description !== null && (
                        <div className="products__parent products__parent--img">
                            <div className="products__content">
                                <div className="products__header">
                                    <p>{currentCategory[0].Description}</p>
                                </div>
                            </div>
                        </div>
                    )}
            </div>

            <div className="products__parent bg-products">
                <div className="products__list">
                    {categories.length > 0 || products.length > 0 ? (
                        categories.concat(products).map((product) => (
                            <div
                                key={`${product.id}`}
                                className="product__item"
                            >
                                {/* {product.CoverImage &&
                                    product.CoverImage.formats && (
                                        <img
                                            src={
                                                process.env.REACT_APP_IMG_URL +
                                                product.CoverImage.formats.small.url
                                            }
                                            alt={product.Name}
                                        />
                                    )} */}
                                {product.CoverImage &&
                                    product.CoverImage.formats && (
                                        <img
                                            src={
                                                product.CoverImage.formats.small
                                                    ? process.env
                                                          .REACT_APP_IMG_URL +
                                                      product.CoverImage.formats
                                                          .small.url
                                                    : product.CoverImage.formats
                                                          .thumbnail
                                                    ? process.env
                                                          .REACT_APP_IMG_URL +
                                                      product.CoverImage.formats
                                                          .thumbnail.url
                                                    : "https://apiwm.modelforge.pl/uploads/logo_wm_a4d059b93c.jpg"
                                            }
                                            alt={product.Name}
                                        />
                                    )}

                                <div className="product__item--data">
                                    <h3>{product.Name}</h3>
                                </div>
                                <div className="product__item--btn">
                                    <Link
                                        className="link--btn"
                                        to={
                                            type === "categories"
                                                ? `/kategorie/${product.Slug}`
                                                : `${product.Slug}`
                                        }
                                    >
                                        Więcej
                                    </Link>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="product__item--notFound" key="no-items">
                            <p>
                                Niestety, jeszcze nie posiadmy produtków w tej
                                kategorii
                            </p>
                            <button
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Wróć na poprzednią stronę
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
