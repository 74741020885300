import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import "./App.scss";
import { RootLayout, SubPageLayout } from "./layouts";
import {
    About,
    Certificates,
    ContactUs,
    Home,
    ProductDetails,
    Products,
    Works,
} from "./pages";
import { productDetailsLoader } from "./pages/ProductDetails";
import NotFound from "./pages/NotFound";

import { HelmetProvider } from "react-helmet-async";

const routes = [
    { path: "", element: <Products /> },
    { path: ":categorySlug", element: <Products /> },
    { path: ":categorySlug/:productSlug", element: <ProductDetails /> },
  ];

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />} errorElement={<NotFound />}>
            <Route index element={<Home />} />
            <Route path="o-nas" element={<About />} />
            <Route path="kontakt" element={<ContactUs />} />
            <Route path="certyfikaty" element={<Certificates />} />

            <Route path="kategorie" element={<SubPageLayout />}>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>

            <Route path="realizacje" element={<Works />}/>

            <Route path="*" element={<NotFound />} />
        </Route>
    )
);

function App() {
    return (
        <HelmetProvider>
            <div className="app">
                <RouterProvider router={router} />
            </div>
        </HelmetProvider>
    );
}

export default App;
