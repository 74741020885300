import "./CertificatesList.scss";

import React, { useState, useEffect } from "react";
import CertCard from "./CertCard";
import apiService from "../../ApiService";


const CertificatesList = () => {
    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        const fetchCerts = async () => {
            try {
                const certsData = await apiService.fetchData(
                    `/certificates?populate=*`
                );

                setCertificates(certsData?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchCerts();
    }, []);


    return (
        <div className="certs__parent">
            <div className="certs__content">
                <div className="certs__list">
                    {certificates.length > 0 && certificates.map((cert, index) => (
                        <CertCard
                            key={index}
                            cert={cert}

                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CertificatesList;
