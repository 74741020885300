import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "./Work.scss";

const Work = (props) => {
    const [toggler, setToggler] = useState(false);


    return (
        <div key={props.id}>
            <div className="work__item" onClick={() => setToggler(!toggler)}>
                <div className="work__desc">
                    <p>{props.work.Description}</p>
                </div>

                <img
                    key={props.work.id}
                    src={process.env.REACT_APP_IMG_URL + props.work.CoverImage.formats.small.url}
                    alt={props.work.Title}
                />

                <div className="work__data">
                    <h2>{props.work.Title}</h2>
                </div>
            </div>

            <FsLightbox
                toggler={toggler}
                sources={props.work.GalleryImages.map((image) => {
                    return process.env.REACT_APP_IMG_URL + image.formats.large.url;
                })}
                showThumbsOnMount={true}
            />
        </div>
    );
};

export default Work;
