import React, { useEffect, useState } from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdHome } from "react-icons/io";
import { motion } from "framer-motion";
import SEO from "../components/seo/SEO";
import apiService from "../ApiService";


import "./ContactUs.scss";

const ContactUs = () => {

  const [contactData, setContactData] = useState();


  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await apiService.fetchData(
                `/contact`
            );

            setContactData(data?.data || []);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, []);





  return (
    <>
      <SEO
        title="Wojmixx Kontakt "
        description="Projektowanie, produkcja oraz montaż zabezpieczeń przeciwpowodziowch "
        name="Wojmixx Kontakt"
        type="article"
      />

      <div className="contactus__parent">
        <div className="contactus__container">
          <div className="contactus__text">
            <h1>Kontakt</h1>
            <p>Potrzebujesz pomocy w wyborze najlepszego rozwiązania?</p>
            <p>
              Potrzebujesz więcej informacji na temat naszych usług lub
              produktów?
            </p>
            <p>
              <span>
                Zadzwoń, doradzimy Ci na każdym etapie Twojej inwestycji!
              </span>
            </p>

{ contactData && <div className="contactus__cards">
            <motion.a
              initial={{ scale: 1, opacity: 1 }}
              whileInView={{ scale: [1, 1.05, 1], opacity: 1 }}
              transition={{ times: [0, 0.3, 1], delay: 1, duration: 1 }}
              href={`mailto:${contactData.Email}`}
              className="contact-card"
            >
              <MdOutlineAlternateEmail />
              {contactData.Email}
            </motion.a>

            <motion.a
              initial={{ scale: 1, opacity: 1 }}
              whileInView={{ scale: [1, 1.05, 1], opacity: 1 }}
              transition={{ times: [0, 0.3, 1], delay: 1.2, duration: 1 }}
              href={`tel:${contactData.Phone1}`}
              className="contact-card"
            >
              <BsFillTelephoneFill />
              {contactData.Phone1}
            </motion.a>

            <motion.a
              initial={{ scale: 1, opacity: 1 }}
              whileInView={{ scale: [1, 1.05, 1], opacity: 1 }}
              transition={{ times: [0, 0.3, 1], delay: 1.2, duration: 1 }}
              href={`tel:${contactData.Phone2}`}
              className="contact-card"
            >
              <BsFillTelephoneFill />
              {contactData.Phone2}
            </motion.a>

            <motion.a
              initial={{ scale: 1, opacity: 1 }}
              whileInView={{ scale: [1, 1.05, 1], opacity: 1 }}
              transition={{ times: [0, 0.3, 1], delay: 1.4, duration: 1 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://maps.app.goo.gl/3zE8YJmJM82N1qZ8A"
              className="contact-card"
            >
              <IoMdHome />
              {contactData.Address}
            </motion.a>
          </div>}
        </div>

          <div className="contactus__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d640681.1671390488!2d16.50044971997075!3d51.15160574494258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fefe21cf1da83%3A0x7b2b0931c64a9d8c!2sFirma%20Wdro%C5%BCeniowo-Konsultingowa%20WOJMIXX!5e0!3m2!1spl!2spl!4v1717522952611!5m2!1spl!2spl"
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="WojMixx Lokalizacja na mapie"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
