import React, { useEffect, useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import apiService from "../../ApiService";


import './Tabs.scss';


const Tabs = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleTab = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const [data, setData] = useState();

    useEffect(() => {
        const fetchWorks = async () => {
            try {
                const data = await apiService.fetchData(
                    `/about-tab?populate=*`
                );
    
                setData(data?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
  
        fetchWorks();
    }, []);
  
  
    return (
      <div className="tabs__parent">
        <div className="tabs__content">
        <h1>Dowiedz Się O Nas Więcej!</h1>
          {data && data.Tab.map((tab, index) => (
            <div className="tabs__item" key={index}>
              <div className="tabs__header" onClick={() => toggleTab(index)}>
                <h2>{tab.Title}</h2>
                <div className="icon">
                {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
              <p className={activeIndex === index ? "" : "tab--invisible"} >{tab.Description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Tabs;
  








// import React from 'react';
// import './Tabs.scss';
// import tabsData from './tabsData.json';

// const Tabs = () => {
//   return (
//     <div className="tabs__parent">
//       <div className="tabs__content">
//         <h1>Dowiedz Się O Nas Więcej!</h1>
//         {tabsData.map((tab, index) => (
//           <div className="tabs__item" key={index}>
//             <h2>{tab.title}</h2>
//             <p>{tab.text}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Tabs;
