import React from 'react'
import CertificatesList from '../components/certs/CertificatesList'
import SEO from '../components/seo/SEO'

const Certificates = () => {
  return (
    <>
        <SEO
        title="Wojmixx Certyfikaty i standardy jakości"
        description="Projektowanie, produkcja oraz montaż zabezpieczeń przeciwpowodziowch "
        name="Wojmixx Certyfikaty i standardy jakości."
        type="article"
      />
    <CertificatesList />
    </>
  )
}

export default Certificates