import React from 'react'
import { Outlet } from 'react-router-dom'
import './SubPageLayout.scss'
import { Breadcrumbs } from '../components'

const SubPageLayout = () => {
  return (
    <div className='subPageLayout__parent'>
      <Breadcrumbs />
      <Outlet/>
    </div>
  )
}

export default SubPageLayout