import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Breadcrumbs.scss";
import apiService from "../../ApiService";

export default function Breadcrumbs() {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBreadcrumbs = async () => {
            const pathSegments = location.pathname.split("/").filter(Boolean);
            if (pathSegments.length === 0) return;

            const lastSegment = pathSegments[pathSegments.length - 1];
            try {
                // Fetch kategorie dla ostatniego segmentu
                const response = await apiService.fetchData(
                    `/categories?filters[Slug][$eq]=${lastSegment}&populate[parent][populate][parent][populate]=parent`
                );

                const categoryData = response?.data[0];

                if (categoryData) {
                    const breadcrumbsPath = [];
                    let currentCategory = categoryData;

                    while (currentCategory) {
                        breadcrumbsPath.unshift({
                            name: currentCategory.Name,
                            slug: currentCategory.Slug,
                        });
                        currentCategory = currentCategory.parent || null;
                    }

                    setBreadcrumbs(breadcrumbsPath);

                } else {
                    const secondLastSegment =
                        pathSegments.length > 1
                            ? pathSegments[pathSegments.length - 2]
                            : null;

                    if (secondLastSegment) {
                        const response = await apiService.fetchData(
                            `/categories?filters[Slug][$eq]=${secondLastSegment}&populate[parent][populate][parent][populate]=parent`
                        );

                        const categoryData = response?.data[0];

                        const breadcrumbsPath = [];
                        let currentCategory = categoryData;

                        while (currentCategory) {
                            breadcrumbsPath.unshift({
                                name: currentCategory.Name,
                                slug: currentCategory.Slug,
                            });
                            currentCategory =
                                currentCategory.parent || null;
                        }

                        breadcrumbsPath.push({
                            name: lastSegment.replaceAll("-", " "),
                            slug: null,
                        });

                        setBreadcrumbs(breadcrumbsPath);
                    }
                }
            } catch (err) {
                console.error("Error fetching breadcrumbs data:", err);
                setError("Failed to fetch breadcrumbs.");
            }
        };

        fetchBreadcrumbs();
    }, [location]);

    if (error) {
        return <></>;
    }


    return (
        <div className="breadcrumbs__parent">
            <div className="bredcrumbs__content">
                <div className="crumb">
                    <Link className="crumbLink" to="/">
                        Start
                    </Link>
                </div>
                <div className="crumb">
                    <Link className="crumbLink" to="/kategorie">
                        Kategorie
                    </Link>
                </div>
                {breadcrumbs.map((breadcrumb, index) => (
                    <div className="crumb" key={index}>
                        {breadcrumb.slug ? (
                            <Link
                                className="crumbLink"
                                to={`/kategorie/${breadcrumb.slug}`}
                            >
                                {breadcrumb.name}
                            </Link>
                        ) : (
                            <span className="crumbLink">{breadcrumb.name}</span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

// import React, { useState, useEffect } from "react";
// import apiService from "../../ApiService";
// import { useLocation } from "react-router-dom";

// const Breadcrumbs = () => {
//     const [categoriesData, setCategoriesData] = useState([]);
//     const [error, setError] = useState(null);

//     const location = useLocation().pathname;

//     const slug = "xd"

//     useEffect(() => {
//         const fetchProduct = async () => {
//             try {
//                 const response = await apiService.fetchData(
//                     `/categories?filters[Slug][$eq]=${slug}&populate[parent][populate][parent][populate]=parent`
//                 );
//                 setCategoriesData(response?.data || []);
//             } catch (err) {
//                 console.error("Error fetching data:", err);
//                 setError("Failed to fetch categories.");
//             }
//         };

//         fetchProduct();
//     }, [location]);

//     // if (error) {
//     //     return <div>{error}</div>;
//     // }

//     // if (!categoriesData.length) {
//     //     return <div>Loading...</div>;
//     // }

//     // return (
//     //     <div>
//     //         {categoriesData.map((category) => (
//     //             {/* <div key={category.id}>{category.attributes.name}</div> */}
//     //         ))}
//     //     </div>
//     // );
//     return <p>Dupa :D</p>
// };

// export default Breadcrumbs;

// import React from "react";
// import { Link, useLocation } from "react-router-dom";

// import "./Breadcrumbs.scss";

// export default function Breadcrumbs() {
//     const location = useLocation();

//     let currentLink = "";

//     const crumb = location.pathname
//         .split("/")
//         .filter((crumb) => crumb !== "")
//         .map((crumb) => {
//             currentLink += `/${crumb}`;

//             return (
//                 <div className="crumb" key={crumb}>
//                     <Link className="crumbLink" to={currentLink}>
//                         {" "}
//                         {crumb.replaceAll("-", " ")}{" "}
//                     </Link>
//                 </div>
//             );
//         });

//     return (
//         <div className="breadcrumbs__parent">
//             <div className="bredcrumbs__content">
//                 <div className="crumb">
//                     <Link className="crumbLink" to="/">
//                         Start
//                     </Link>
//                 </div>
//                 {crumb}
//             </div>
//         </div>
//     );
// }
