import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineAlternateEmail } from "react-icons/md";

import { LogoColor } from "../../assets/images";
import apiService from "../../ApiService";


import "./Footer.scss";

export default function Footer() {

    const [contactData, setContactData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiService.fetchData(`/contact`);

                setContactData(data?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="footer__parent">
            <div className="footer__content">
                <div className="footer__content--column">
                    <div>
                        <Link className="footer__logo" to="/">
                            <img src={LogoColor} alt="WojMixx Logo" />
                        </Link>
                    </div>
                    <p className="logo-txt">
                        FIRMA WDROŻENIOWO-KONSULTINGOWA WOJMIXX SP. Z O.O. SP.K.
                    </p>
                </div>

                <div className="footer__content--column"></div>

                <div className="footer__content--column">
                    <h3>Mapa Strony</h3>
                    <ul>
                        <li>
                            <Link to="/">Start</Link>
                        </li>
                        <li>
                            <Link to="/o-nas">O nas</Link>
                        </li>
                        <li>
                            <Link to="/kategorie">Produkty</Link>
                        </li>
                        <li>
                            <Link to="/realizacje">Realizacje</Link>
                        </li>
                        <li>
                            <Link to="/certyfikaty">Certyfikaty</Link>
                        </li>
                        <li>
                            <Link to="/kontakt">Kontakt</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer__content--column">
                    <h3>Kontakt</h3>

                    <p className="footer--p">
                        <MdOutlineAlternateEmail size={23} />
                        <a href={`mailto:${contactData?.Email}`}>{contactData?.Email}</a>
                    </p>
                    <p className="footer--p">
                        <BsFillTelephoneFill size={23} />
                        <a href={`tel:${contactData?.Phone1}`}>{contactData?.Phone1}</a>
                    </p>
                    <p className="footer--p">
                        <BsFillTelephoneFill size={23} />
                        <a href={`tel:${contactData?.Phone2}`}>{contactData?.Phone2}</a>
                    </p>
                    <p className="footer--p">
                        <IoMdHome size={25} />
                        <a href="https://maps.app.goo.gl/y9QGBz7KNqe2wtPE7">
                        {contactData?.Address}
                        </a>
                    </p>
                </div>
            </div>

            <div className="footer--separator" />
            <p className="footer--cp">
                {" "}
                ©2024 FIRMA WDROŻENIOWO-KONSULTINGOWA WOJMIXX SP. Z O.O. SP.K.{" "}
            </p>
        </div>
    );
}
