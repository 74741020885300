import React from 'react'
import Heading from '../components/about/Heading'
import Partners from '../components/about/Partners'
import Tabs from '../components/about/Tabs'
import SEO from '../components/seo/SEO'


const About = () => {
  return (
    <div>    
      <SEO
        title="Wojmixx Bariery przeciwpowodziowe "
        description="Projektowanie, produkcja oraz montaż zabezpieczeń przeciwpowodziowch "
        name="Wojmixx Bariery przeciwpowodziowe"
        type="article"
      />
      <Heading />
      <Tabs />
      <Partners />
    </div>
  )
}

export default About