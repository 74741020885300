













import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import './SwiperComponent.scss'

const SwiperComponent = (params) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);



    

  return (
    <div className="product__swiper">
      <Swiper
        spaceBetween={10} 
        slidesPerView={1} 
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiper__main"
      >
        {params.largeGallery.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Product ${index + 1}`} />
          </SwiperSlide>
        ))}



      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiper__thumbs"
      >
        {params.thumbnailGallery.map((image, index) => (
          <SwiperSlide key={index}>
            <img  src={image} alt={`Product ${index + 1}`} />
          </SwiperSlide>
        ))}

      </Swiper>
    </div>
  )
}

export default SwiperComponent