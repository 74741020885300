import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import "./Testimonials.scss";
import apiService from "../../ApiService";

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiService.fetchData(
                    `/testimonials?populate=*`
                );

                setTestimonials(data?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="testimonials__parent">
            <div className="testimonials__container">
                <h1>
                    Opinie Klientów <span>O Naszych</span> Produktach i Usługach
                </h1>
                {testimonials.length > 0 && (
                    <>
                        <div className="testimonial-item app__flex">
                            {testimonials[currentIndex].CoverImage !== null ? (
                                <img
                                    src={
                                        process.env.REACT_APP_IMG_URL +
                                        testimonials[currentIndex].CoverImage
                                            .formats.small.url
                                    }
                                    alt="testimonial"
                                />
                            ) : (
                                <img
                                    src={process.env.REACT_APP_IMG_URL + "/uploads/avatar_1577909_640_8c712fbe2f.png"}
                                    alt="testimonial"
                                />
                            )}
                            <div className="testimonial-content">
                                <p className="p-text">
                                    {testimonials[currentIndex].Text}
                                </p>
                                <div>
                                    <h4 className="bold-text">
                                        {testimonials[currentIndex].Name}
                                    </h4>
                                    <h5 className="p-text">
                                        {testimonials[currentIndex].Company}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="testimonial-btns app__flex">
                            <div
                                className="app__flex"
                                onClick={() =>
                                    handleClick(
                                        currentIndex === 0
                                            ? testimonials.length - 1
                                            : currentIndex - 1
                                    )
                                }
                            >
                                <HiChevronLeft />
                            </div>
                            <div
                                className="app__flex"
                                onClick={() =>
                                    handleClick(
                                        currentIndex === testimonials.length - 1
                                            ? 0
                                            : currentIndex + 1
                                    )
                                }
                            >
                                <HiChevronRight />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Testimonials;
