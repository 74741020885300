import React, { useState } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { motion } from "framer-motion";
import { useSpring, animated } from "react-spring";

import "./Hero.scss";
import Slider from "./Slider";

function Number({ n }) {
    const [isVisible, setIsVisible] = useState(false);

    const { number } = useSpring({
        number: isVisible ? n : 0,
        from: { number: 0 },
        delay: 500,
        config: { mass: 1, tension: 20, friction: 10 },
    });

    return (
        <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
            <animated.span>{number.to((n) => n.toFixed(0))}</animated.span>
        </VisibilitySensor>
    );
}

const countYears = () => {
    const currentYear = new Date().getFullYear();
    return currentYear - 2006;
};

const Hero = () => {
    return (
        <div className="hero__parent">
            <div className="hero">
                <motion.div
                    className="hero__content"
                    whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                >
                    <h1>Firma WojMixx pomaga</h1>
                    <p>
                    zabezpieczyć obiekty i obszary przed powodzią oraz skażeniem środowiska dzięki mobilnym systemom ochrony i barierom retencyjnym. Dodatkowo umożliwia przygotowanie komór do remontów poprzez montaż zamknięć remontowych.                    </p>
                    <p className="sub-text">Działamy w branży od 2006 roku.</p>

                    <div className="hero__content__btns">
                        <Link
                            to="/o-nas"
                            className="hero__content__navlink navlink-blue"
                        >
                            Dowiedz Się O Nas Więcej
                        </Link>
                        <Link
                            to="/kategorie"
                            className="hero__content__navlink navlink-white"
                        >
                            Zobacz Katalog Produktów
                        </Link>
                    </div>

                    <div className="hero__content__data">
                        <div className="hero__content__data--item">
                            <p className="data--number">
                                <Number n={25} />+
                            </p>
                            <div />
                            <p className="data--text">Produktów w ofercie</p>
                        </div>

                        <div className="hero__content__data--item">
                            <p className="data--number">
                                <Number n={200} />+
                            </p>
                            <div />
                            <p className="data--text">
                                Realizacji w całej Polsce
                            </p>
                        </div>

                        <div className="hero__content__data--item">
                            <p className="data--number">
                                <Number n={countYears()} />
                            </p>
                            <div />
                            <p className="data--text">
                                Lat doświadczenia w branży
                            </p>
                        </div>
                    </div>
                </motion.div>

                <Slider />
            </div>
        </div>
    );
};

export default Hero;
