import React from "react";
import "./NotFound.scss";
import { LogoColor } from "../assets/images";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not__found">
            <img src={LogoColor} alt="WojMixx Logo" />
            <h2>Ups... Wygląda na to że strona której szukasz nie istnieje.</h2>
            <Link to="/" className="hero__content__navlink navlink-white">
                Przejdź do strony głównej
            </Link>
        </div>
    );
};

export default NotFound;
