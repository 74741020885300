import React from "react";
import { motion } from "framer-motion";
import "./InfoCard.scss";

const InfoCard = ({ delay, svg: SvgIcon, title, info }) => {
    return (
        <motion.div
            initial={{ scale: 1, opacity: 1 }}
            whileInView={{ scale: [1, 1.05, 1], opacity: 1 }}
            transition={{ times: [0, 0.3, 1], delay, duration: 1 }}
            className="info__card"
        >
            <div className="badge"></div>
            <SvgIcon />
            <p className="card--title">{title}</p>
            <p className="card--info">{info}</p>
        </motion.div>
    );
};

export default InfoCard;
