import React, { useEffect, useState } from "react";
import {
    Link,
    useLoaderData,
    useLocation,
    useNavigate,
} from "react-router-dom";

import "./ProductDetails.scss";
import SwiperComponent from "../components/ProdcutDetails/SwiperComponent";
import apiService from "../ApiService";
import { a } from "react-spring";
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import rehypeRaw from 'rehype-raw';
import MarkdownContent from "../components/ProdcutDetails/MarkdownComponent";



const ProductDetails = () => {
    // const navigate = useNavigate();

    // const { slug } = useParams();

    const navigate = useNavigate();
    const location = useLocation().pathname;

    const [product, setProduct] = useState([]);
    const [thumbnailGallery, setThumbnailGallery] = useState([]);
    const [largeGallery, setLargeGallery] = useState([]);
    const [tags, setTags] = useState([]);

    // Funkcja pomocnicza do wyciągnięcia ostatniego segmentu z URL
    function getLastSegment(path) {
        const segments = path.split("/");
        return segments.filter(Boolean).pop();
    }

    useEffect(() => {
        // Pobierz kategorie i produkty z API
        const fetchProduct = async () => {
            try {
                const productSlug = getLastSegment(location);
                //       const categorySlug = getLastSegment(location);
                //       const categoryFilter =
                //         categorySlug && categorySlug !== "kategorie"
                //           ? `?filters[parent][slug][$eq]=${categorySlug}`
                //           : "?filters[parent][$null]=true";

                //       const categoriesData = await apiService.fetchData(
                //         `/categories${categoryFilter}`
                //       );
                const productData = await apiService.fetchData(
                    `/products?filters[Slug]=${productSlug}&populate[DetailsBlocks][populate]=*&populate[GalleryImages][populate]=*&populate[Tag][populate]=*&populate[Files][populate]=*`
                );


                //       setCategories(categoriesData?.data || []);
                setProduct(productData?.data[0] || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchProduct();
    }, [location]);




    useEffect(() => {
        if (product.GalleryImages) {
            let thumbnail = [];
            let large = [];
            product.GalleryImages.map((image) => {
              thumbnail.push(
                image.formats?.thumbnail
                  ? process.env.REACT_APP_IMG_URL + image.formats.thumbnail.url
                  : "https://apiwm.modelforge.pl/uploads/logo_wm_a4d059b93c.jpg"
              );
              large.push(
                image.formats?.large
                  ? process.env.REACT_APP_IMG_URL + image.formats.large.url
                  : image.formats?.small
                  ? process.env.REACT_APP_IMG_URL + image.formats.small.url
                  : image.formats?.thumbnail
                  ? process.env.REACT_APP_IMG_URL + image.formats.thumbnail.url
                  : "https://apiwm.modelforge.pl/uploads/logo_wm_a4d059b93c.jpg"
              );
            });
            setThumbnailGallery(thumbnail);
            setLargeGallery(large);
          }

        if (product.Tag) {
            let tags = [];
            product.Tag.map((tag) => {
                tags.push(tag.Tag);
            });
            setTags(tags);
        }

    }, [product]);

    return (
        <div className="product__parent">
            <div className="product__content">
                <h1 className="product--name" >{product.Name}</h1>

                {product.Tag && (
                    <div className="product__badges">
                        {tags.map((tag, index) => (
                            <p key={index}>{tag}</p>
                        ))}
                    </div>
                )}

                <div className="product__data">
                {largeGallery.length > 0 && (
    <SwiperComponent
        thumbnailGallery={thumbnailGallery}
        largeGallery={largeGallery}
    />
)}

                    {product && product.DetailsBlocks && (
                        <div className="product__description">
                            {product.DetailsBlocks.map((item, index) => (
                                <div className="data__block" key={index}>
                                    <h2 className="data--title">
                                        {item.Title}
                                    </h2>
                                    {item.Details.map((detail, index) => (
                                        <div className="data--data" key={index}>
                                            <div className="dot" />

                                            {
                                                <p className="data--bold data--item">
                                                    {detail.Name}
                                                    {detail.Value == null
                                                        ? ""
                                                        : ":"}
                                                </p>
                                            }

                                            {detail.Value == null ? (
                                                <></>
                                            ) : (
                                                <p
                                                    className={
                                                        detail.Value == null
                                                            ? "data--item--full"
                                                            : "data--item"
                                                    }
                                                >
                                                    {detail.Value}
                                                </p>
                                            )}

                                            {/* {detail.Name == null ? (
                                            <></>
                                        ) : (
                                            <p className="data--bold data--item">
                                                {detail.Name}:
                                            </p>
                                        )}

                                    
                                        <p
                                            className={
                                                detail.Name == null
                                                    ? "data--item--full"
                                                    : "data--item"
                                            }
                                        >
                                            {detail.Value}
                                        </p> */}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="product__text">
                    <h2 className="data--title">Opis produktu</h2>
                    {/* <p className="data--data">{product.Description}</p> */}
                    <MarkdownContent content={product.Description} />

                </div>

{product?.Files && product?.Files.length > 0 &&
               <div className="product__text">
                    <h2 className="data--title">Pliki do pobrania</h2>
<div className="product__files">
                    {product.Files.map((file, index) => (
                        <a key={index}
                        href={process.env.REACT_APP_IMG_URL + file.url}
                        target="_blank"
                        rel="noreferrer">
                        {file.name}
                         </a>
                    ))}
                    </div>
                </div>}

                <div className="product__cta">
                    <div className="cta--data">
                        <p>
                            Jesteś zainteresowany tym produktem lub potrzebujesz
                            więcej intofmacji?
                        </p>
                        <p>Skontakuj się z naszym doradzcą!</p>
                    </div>
                    <div className="cta__container">
                        <Link to="/kontakt" className="cta--btn">
                            Skontaktuj się z nami
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
