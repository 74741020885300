import React, { useState } from "react";
import "./CertCard.scss";
import FsLightbox from "fslightbox-react";

const CertCard = ({ cert }) => {
    const [toggler, setToggler] = useState(false);


    return (
        <>
            <div className="cert__item" onClick={() => setToggler(!toggler)}>
                <img src={process.env.REACT_APP_IMG_URL + cert.CoverImage.formats.medium.url} alt={`${cert.Title}`} />

                <div className="cert__data">
                    <h2>{cert.Title}</h2>
                </div>
            </div>

            <FsLightbox
                toggler={toggler}
                sources={[process.env.REACT_APP_IMG_URL + cert.CoverImage.formats.large.url]}
                showThumbsOnMount={true}
            />
        </>
    );
};

export default CertCard;
