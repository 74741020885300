import "./Heading.scss";
import React, { useEffect, useState } from "react";
import apiService from "../../ApiService";



const Heading = () => {

  const [data, setData] = useState();

  useEffect(() => {
      const fetchWorks = async () => {
          try {
              const data = await apiService.fetchData(
                  `/about?populate=*`
              );

              setData(data?.data || []);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };

      fetchWorks();
  }, []);


    return (
        <div className="about__parent">
            <div className="about__content">
                {data && <div className="about__item">
                    <div className=" about__block about__block--txt">
                        <h1 className="about__heading">{data.Title}</h1>

                        <p>
                           {data.Text}
                        </p>
                        <h2>{data.SubTitle}</h2>
                        <p>
                            {data.SecondText}
                        </p>
                    </div>

                    <div className=" about__block about__block--img">
                        <img
                            src={process.env.REACT_APP_IMG_URL + data.CoverImage.formats.medium.url}
                            alt="Firma Wojmixx"
                        />
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Heading;
