import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from "framer-motion";

import './Navbar.scss'
import { LogoColor } from '../../assets/images';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {

    const [nav, setNav] = useState(false);

    const handleClick = () => {
        setNav(!nav);
    };


  return (
    <div className='navbar'>
        <div className="navbar__container">
            <NavLink className="navbar__logo" to="/">

                <img src={LogoColor} alt="WojMixx Logo" />
                {/* <img src={Logo} alt="WojMixx Logo" /> */}

            </NavLink>

            <div className="navbar__menu">


                <NavLink className='navLink' to="/">Start<div className="navLink__container__box"/></NavLink>
                <NavLink className='navLink' to="o-nas">O Nas<div className="navLink__container__box"/></NavLink>
                <NavLink className='navLink' to="kategorie">Produkty<div className="navLink__container__box"/></NavLink>
                <NavLink className='navLink' to="realizacje">Realizacje<div className="navLink__container__box"/></NavLink>
                <NavLink className='navLink' to="certyfikaty">Certyfikaty<div className="navLink__container__box"/></NavLink>
                <NavLink className='navLink' to="kontakt">Kontakt<div className="navLink__container__box"/></NavLink>
            </div>

            <div className="navbar__mobile" onClick={handleClick}>
            {nav ? "" : <AiOutlineMenu size={20} />}
            </div>

            {nav &&
                <div 
                className="navbar__mobile__menu" 
                onClick={handleClick}
                whileInView={{ x: [220, 0] }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <div className="navbar__mobile__menu-colose">
                    <AiOutlineClose size={20} />
                    </div>
                    <NavLink className='navLink-mobile' to="/">Start</NavLink>
                    <NavLink className='navLink-mobile' to="o-nas">O Nas</NavLink>
                    <NavLink className='navLink-mobile' to="kategorie">Produkty</NavLink>
                    <NavLink className='navLink-mobile' to="realizacje">Realizacje</NavLink>
                    <NavLink className='navLink-mobile' to="certyfikaty">Certyfikaty</NavLink>
                    <NavLink className='navLink-mobile' to="kontakt">Kontakt</NavLink>
                </div>
            }

        </div>
    </div>
  )
}

export default Navbar