import React from "react";
import { PiHandshake } from "react-icons/pi";
import { PiFactory } from "react-icons/pi";
import { MdHomeWork } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { BsTrophy } from "react-icons/bs";
import { FaChartColumn } from "react-icons/fa6";

import "./Info.scss";
import InfoCard from "./InfoCard";

const Info = () => {
    return (
        <div className="info__parent">
            <div className="info__content">
                <div className="info__text info__component">
                    <h1>
                        Rozwiązania dopasowane do <span>twoich</span> potrzeb
                    </h1>
                    <p className="p-text">
                        Działamy indywidualnie a także z biurami projektowmi,
                        specjalizującymi się w projektowaniu obiektów
                        mieszkaniowych i przemysłowych. Oferujemy rzetelną
                        obsługę deweloperów i przemysłu.
                    </p>
                    <p className="sub-text">
                        Kompleksowo przeprowadzimy Twoją inwestycje na każdym
                        etapie zaawansowania
                    </p>
                </div>

                <div className="info__cards info__component">
                    <InfoCard
                        delay={1}
                        svg={FaChartColumn}
                        title="Doradztwo"
                        info="Zapewniamy wsparcie w zakresie doboru odpowiednich rozwiązań"
                    />
                    <InfoCard
                        delay={1.1}
                        svg={PiHandshake}
                        title="Współpraca"
                        info="Współpracujemy z projektantami, deweloperami i przemysłem"
                    />
                    <InfoCard
                        delay={1.2}
                        svg={PiFactory}
                        title="Produkcja"
                        info="Posiadamy własną nowoczesną, linię produkcyjną"
                    />
                    <InfoCard
                        delay={1.3}
                        svg={MdHomeWork}
                        title="Montaż"
                        info="Posiadamy własny zespół montażowy"
                    />
                    <InfoCard
                        delay={1.4}
                        svg={FaPeopleGroup}
                        title="Partnerstwo"
                        info="Sprzedajemy systemy partnerów: Thomas, Flutz-Schutz"
                    />
                    <InfoCard
                        delay={1.5}
                        svg={BsTrophy}
                        title="Jakość"
                        info="Gwarantujemy zniewawodne rozwiązania przeciwpowodziowe"
                    />
                </div>
            </div>
        </div>
    );
};

export default Info;
