import React from "react";
import { Outlet } from "react-router-dom";

import "./RootLayout.css";
import { Footer, Navbar } from "../components";
import { ScrollToTop } from "../tools";


const RootLayout = () => {
  return (
    <ScrollToTop>
    <div className="root-layout">

      <header>
        <nav>
          <Navbar />
        </nav>
        {/* <Breadcrumbs /> */}
      </header>
      <main>
        <Outlet />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
    </ScrollToTop>
  );
};

export default RootLayout;
