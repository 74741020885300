import React, { useEffect, useState } from "react";
import Work from "../components/Works/Work";
import SEO from "../components/seo/SEO";
import "./Works.scss";
import apiService from "../ApiService";



export default function Works() {
    const [works, setWorks] = useState([]);

    useEffect(() => {
        const fetchWorks = async () => {
            try {
                const worksData = await apiService.fetchData(
                    `/works?populate=*`
                );


                setWorks(worksData?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchWorks();
    }, []);

    return (
        <>
            <SEO
                title="Wojmixx Realizacje"
                description="Zabezpieczenia przeciwpowodziowe - nasze realizacje. "
                name="Wojmixx Nasze realizacje."
                type="article"
            />
            <div className="works__parent">
                <div className="works__content">
                    {works.length > 0 && <div className="works__list">
                        {works.map((work) => (
                            <Work key={work.id} work={work} />
                        ))}
                    </div>}
                </div>
            </div>
        </>
    );
}
